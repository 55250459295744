import { checkMobilePlanCustomerAccountStatus } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export async function useBouyguesAccount(email: string) {
  const {
    data: bouyguesAccount,
    pending,
    error,
    refresh,
  } = useHttpFetch(checkMobilePlanCustomerAccountStatus, {
    body: {
      partner: 'BOUYGUES_TELECOM',
      email,
    },
  })

  return {
    bouyguesAccount,
    pending,
    error,
    refresh,
  }
}
