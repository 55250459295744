import {
  FORM_VALID,
  type ValidationContext,
  type ValidationResult,
} from '@ds/components/Form'

export function validateBirthdate<Values extends object>(errorMessage: string) {
  return (
    value: unknown,
    _values: Values,
    _context: ValidationContext,
  ): ValidationResult => {
    if (typeof value !== 'string') return errorMessage

    const birthdate = new Date(value)

    if (birthdate.getUTCFullYear() < 1900) return errorMessage

    return FORM_VALID
  }
}
